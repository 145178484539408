"use strict"

import * as universal from '@/lib/core/universal'
import * as jsf from '@/lib/js_functions'
import { linkDataVersionsManager } from '@/lib/core/linkDataVersionsManager'
import { lotes } from '@/lib/core/terrenos/lotes'
import { zoneamentos } from '@/lib/core/terrenos/zoneamentos'
import { operacoesUrbanas } from '@/lib/core/terrenos/operacoesUrbanas'
import { macroAreas } from '@/lib/core/terrenos/macroAreas'
import { cartorios } from '@/lib/core/terrenos/cartorios'
export class quadras {
  #LDVM
  #currentDataId
  #lotes
  #zoneamentos
  #operacoesUrbanas
  #macroAreas
  #cartorios
  constructor({ register }) {
    this.#LDVM = new linkDataVersionsManager({ company: 'public', dataVersionName: 'quadras' })
    this.#currentDataId = this.#LDVM?.getCurrentDataId({ register })
    this.#lotes = new lotes({ register })
    this.#zoneamentos = new zoneamentos({ register })
    this.#operacoesUrbanas = new operacoesUrbanas({ register })
    this.#macroAreas = new macroAreas({ register })
    this.#cartorios = new cartorios({ register })
  }
  #getQuadras = async ({ action, query, project }) => (await jsf.getFunc({ func: () => universal.getGenericRegisters({ company: 'public', dataId: this.#currentDataId || 'quadras', action, query, project }), returnData: true })).data
  fillQuadrasFieldOptions = async (FormLines, distrito) => {
    if (!distrito) { return }
    let fieldQuadra = jsf.getJSONObject({ fields: FormLines.fields, name: 'quadra' })
    fieldQuadra.loading = FormLines.styles["--over-color"]
    let options = []
    const quadras = await this.#getQuadras({ query: { "connections._id": distrito }, project: { Setor: 1, Quadra: 1, qd_subqua: 1, qd_tipo: 1 } })
    const hasSubqua = (setor, quadra, tipo) => quadras.filter(_quadra => _quadra.Setor == setor && _quadra.Quadra == quadra && _quadra.qd_tipo == tipo).length > 1
    options = quadras.map(e => ({ option: { text: `${e.qd_tipo} | ${e.Setor}.${e.Quadra}${hasSubqua(e.Setor, e.Quadra, e.qd_tipo) ? ' (' + e.qd_subqua + ')' : ''}`, value: e._id } })).sort((a, b) => a.option.text.localeCompare(b.option.text))
    options.unshift({ "option": { "text": "", "value": "" } })
    fieldQuadra.options = options
    fieldQuadra.loading = 'false'
  }
  cleanFieldsInfosOfQuadra = ({ register, fields }) => {
    ['poligono', 'zoneamentos', 'macroAreas', 'cartorios', 'lotes', 'testadas', 'restricoes']
    .map(field => register[field] = [])
    const fieldLotes = jsf.getJSONObject({ fields, name: 'lotes' })
    fieldLotes.footers[1].text = ''
    fieldLotes.footers[3].text = ''
    register.operacaoUrbana = ''
  }
  setFieldsInfosOfQuadra = async ({ register }) => {
    if (!register.quadra) { return window.$showAlert({ type: 'warning', text: 'Selecione uma quadra!', dismissible: true }) }
    const quadra = await this.#getQuadras({ action: 'read/one', query: { _id: register.quadra }, project: { Poligono: 1, connections: 1 } })
    register.poligono = quadra.Poligono
    await this.#zoneamentos.fillZoneamentosField({ register, quadra })
    await this.#operacoesUrbanas.fillOperacoesUrbanasField({ register, quadra })
    await this.#macroAreas.fillMacroAreasField({ register, quadra })
    await this.#cartorios.fillCartoriosField({ register, quadra })
  }
  reCalculeFieldsByChangeQuadras = async ({ register, fields }) => {
    this.cleanFieldsInfosOfQuadra({ register, fields })
    this.setFieldsInfosOfQuadra({ register })
  }
}