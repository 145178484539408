"use strict"

import * as universal from '@/lib/core/universal'
import * as jsf from '@/lib/js_functions'
import { quadras } from '@/lib/core/terrenos/quadras'
import { lotes } from '@/lib/core/terrenos/lotes'
export class Terrenos {
  #registerOriginal
  #quadras
  #lotes
  constructor({ register }) {
    this.#registerOriginal = { solicitarExcecaoDeQuadra: register.solicitarExcecaoDeQuadra } //pegar do state registerOriginal?
    this.#quadras = new quadras({ register })
    this.#lotes = new lotes({ register })
  }
  get registerOriginal() { return this.#registerOriginal }
  beforeUpdate({ register, custom: { logicExcecaoDeQuadra, update } }) {
    if (register.link_status == "Descartado") return true
    if (logicExcecaoDeQuadra && this.#registerOriginal.solicitarExcecaoDeQuadra == "Sim" && register.excecaoDeQuadraConcedida != "Sim") {
      window.$showAlert({ type: 'error', text: 'Terrenos com solicitação de exceção não podem ser editados!', dismissible: true })
      return false
    }
    if (logicExcecaoDeQuadra && update) { this.#registerOriginal.solicitarExcecaoDeQuadra = register.solicitarExcecaoDeQuadra } //pegar do state registerOriginal?
    return update
  }
  async setNextNumber({ register, custom: { module, dataId, field, fieldLabel } }) {
    try {
      register[field] = await universal.getNextNumber({ module, dataId, field })
      return true
    }
    catch(e) {
      window.$showAlert({ type: 'error', text: `Não foi possível obter o ${fieldLabel} do terreno para efetuar o cadastro`, dismissible: true })
      return false
    }
  }
  async changeDistrito({ FormLines, register, fields }) {
    register.quadra = ''
    this.#quadras.cleanFieldsInfosOfQuadra({ register, fields })
    await this.#quadras.fillQuadrasFieldOptions(FormLines, register.distrito)
  }
  async changeQuadra({ FormLines, register, fields }) {
    await this.#quadras.reCalculeFieldsByChangeQuadras({ register, fields })
    this.#lotes.fillLotesFieldOptions(FormLines, register.quadra)
  }
  async fillOptionsQuadrasAndLotesFields({ FormLines, register }) {
    if (!register.distrito) return
    await this.#quadras.fillQuadrasFieldOptions(FormLines, register.distrito)
    this.#lotes.fillLotesFieldOptions(FormLines, register.quadra)
  }
  procurarEndereco({ FormLines, register }) {
    if (!register.endereco) { return window.$showAlert({ type: 'warning', text: 'Digite uma endereço!', dismissible: true }) }
    const fieldMap = FormLines.components.FieldMap[0]
    fieldMap.functions.setMapCenter({ Map: fieldMap, address: register.endereco, zoom: 20, insertMarker: true })
  }
  limparEndereco({ register }) { register.endereco = '' }
  checkSaoPaulo({ fieldText, fields }) {
    const required = fieldText == 'São Paulo' ? true : false
    const requireFields = ['distrito', 'quadra']
    requireFields.map(fieldName => universal.requireField({ fields, fieldName, required }))
  }
  calculeOfertaValorTotal({ vmodel }) {
    const areaDoTerreno = jsf.numberIn(vmodel.areaDoTerreno)
    const valorporm2 = jsf.numberIn(vmodel.valorporm2)
    const valorTotal = areaDoTerreno * valorporm2
    vmodel.total = jsf.numberOut(valorTotal, 2)
  }
  async checkDuplicatedQuadras({ register }) {
    if (register.solicitarExcecaoDeQuadra == "Sim" || register.excecaoDeQuadraConcedida == "Sim") return true
    if (register.link_cidade == 'São Paulo' && !register.link_quadra) {
      window.$showAlert({ type: 'error', text: 'Selecione uma quadra', dismissible: true })
      return false
    }
    const quadra = register.link_quadra?.split(' (')[0]
    const terrenosAtivosNaQuadra = await universal.getGenericRegisters({ dataId: 'terrenos_all', query: { link_quadra: quadra, link_status: { $nin: ['Descartado', 'Landbank'] } }, project: { _id: 1, responsaveis: 1 } })
    if (!quadra|| !terrenosAtivosNaQuadra.status || terrenosAtivosNaQuadra?.data?.length <= 0 || (terrenosAtivosNaQuadra?.data?.length > 0 && terrenosAtivosNaQuadra?.data?.some(terreno => terreno._id == register._id))) return true
    const responsavelAtual = terrenosAtivosNaQuadra.data.map(terrenoAtivoNaQuadra => terrenoAtivoNaQuadra.responsaveis.getLast())
    let terrenosPorEquipe = []
    const count = responsavel => terrenosPorEquipe.push({ equipe: responsavel.link_equipe, quantidade: jsf.countValuesInArray(responsavelAtual, 'equipe', responsavel.equipe) })
    jsf.executeIfUniq({ array: responsavelAtual, prop: 'equipe', func: count })
    const equipeList = terrenosPorEquipe.reduce((lines, terreno) => lines + `\n${terreno.equipe}: ${terreno.quantidade}`, '')
    window.$showAlert({ type: 'error', text: `Já existem terreno(s) ativo(s) nesta quadra nas equipes: ${equipeList}`, dismissible: true })
    return false
  }
  async checkLimitTerrenosPorEquipe({ register, custom: { dataId, limite, statusChecados } }) {
    const equipe = register.responsaveis?.getLast()?.link_equipe
    if (!equipe) {
      window.$showAlert({ type: 'error', text: 'É necessário vincular o terreno com uma equipe!', dismissible: true })
      return false
    }
    const terrenos = await universal.getGenericRegisters({ dataId, query: { $expr: { $eq: [{ $arrayElemAt: ["$responsaveis.link_equipe", -1] }, equipe] }, link_status: { $in: statusChecados } }, project: { _id: 1 } })
    if (!terrenos.status || !statusChecados.includes(register.link_status) || terrenos?.data.length <= limite || terrenos?.data.some(terreno => terreno._id == register._id)) return true
    window.$showAlert({ type: 'error', text: `Essa equipe já tem ${terrenos.data.length} terrenos ativos. O limite é de ${limite}!`, dismissible: true })
    return false
  }
  solicitarExcecaoDeQuadra({ register }) {
    register.excecaoDeQuadraConcedida = ''
    register.detalhes = ''
  }
  excecaoDeQuadraConcedida({ register, userName }) {
    register.solicitarExcecaoDeQuadra = ''
    register.detalhes = `${userName} - ${jsf.dateToString(new Date(), { addTime: true })}`
  }
  changeStatus({ register, fields, statusName, userName, custom: { logicExcecaoDeQuadra, statusQueDescartam } }) {
    if (!register) { return { status: false, alertType: 'error', alertText: 'Não foi possível acessar o registro!' } }
    if (!fields) { return { status: false, alertType: 'error', alertText: 'Não foi possível acessar os campos!' } }
    const descartes = register.descartes.length
    if (logicExcecaoDeQuadra && (statusName == 'Descartado' || statusName == 'Landbank')) {
      register.solicitarExcecaoDeQuadra = ""
      register.excecaoDeQuadraConcedida = ""
      register.detalhes = ""
      this.#registerOriginal.solicitarExcecaoDeQuadra = ""
    }
    if (statusQueDescartam.some(_status => _status == statusName)) {
      this.addDescarteLine({ register, fields, userName })
      if (!this.checkDescarteLineAdded({ register, descartes })) {
        register.status = ''
        register.link_status = ''
        window.$showAlert({ type: 'error', text: 'Não foi possível descartar! Você pode gravar o registro e entrar nele novamente para tentar de novo.', dismissible: true })
        return
      }
    }
    this.recordHistoric({ register, action: 'changeStatus', label: statusName, userName })
  }
  addDescarteLine({ register, fields, userName }) {
    try {
      jsf.getJSONObject({ fields, name: 'descartes' }).enableFocus = true
      register.descartes.push({
        descartadoPor: userName,
        data: jsf.dateToString(new Date(), { onlyTime: false }),
        horario: jsf.dateToString(new Date(), { onlyTime: true }),
        enabledLine: ''
      })
      return true
    }
    catch (error) {
      window.$showAlert({ type: 'error', text: `Ocorreu um erro: ${error}`, dismissible: true })
      return false
    }
  }
  checkDescarteLineAdded({ register, descartes }) {
    if (register.descartes.length != descartes + 1) { return false }
    return true
  }
  changeEquipe({ register, vmodel, equipeName, userName }) {
    this.recordHistoric({ register, action: 'changeEquipe', label: equipeName, equipeid: vmodel.equipe, userName })
    vmodel.equipeBackup = equipeName
    vmodel.detalhes = jsf.dateToString(new Date(), { addTime: true })
  }
  recordHistoric({ register, action, label, equipeid, userName }) {
    const lastResponsaveis = jsf.getArrayValue(register.responsaveis, 'last')
    const equipeId = action == 'changeEquipe' ? equipeid : jsf.getObjectValue(lastResponsaveis, 'equipe')
    const equipeName = action == 'changeEquipe' ? label : jsf.getObjectValue(lastResponsaveis, 'equipeBackup')
    const status = action == 'changeStatus' ? label : jsf.getObjectValue(register, 'link_status')
    this.addHistoricLine({ register, status, equipeId, equipeName, userName })
  }
  addHistoricLine({ register, status, equipeId, equipeName, userName }) {
    register.historico.push({
      data: jsf.dateToString(new Date(), { onlyTime: false }),
      horario: jsf.dateToString(new Date(), { onlyTime: true }),
      status: status,
      equipe: equipeId,
      equipeBackup: equipeName,
      alteradoPor: userName
    })
  }
  enableAddedResponsavelField({ vmodel }) {
    jsf.getArrayValue(vmodel, 'last').enabledLine = ''
  }
}